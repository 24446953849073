.google-reviews-drawer {
  .ant-rate {
    pointer-events: none;
    touch-action: none;
  }

  .ant-list-item-meta {
    .ant-typography ul li,
    .ant-typography ol li {
      margin: 0;
    }
  }
}
