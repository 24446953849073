.communication-drawer {
  .ant-drawer-wrapper-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // padding-bottom: 188px;
  }

  .ant-drawer-body {
    // height: calc(100% - 56px);
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .conversation-list {
    padding: 6px 2px;
    overflow-y: auto;
    flex: 1 1 100%;

    .last-read-anchor {
      padding-bottom: 40px;

      .last-read-marker {
        text-align: center;
        position: absolute;
        background-color: #fff;
        bottom: -30px;
        left: 0;
        right: 0;
        line-height: 1;

        > span {
          background-color: #fff;
          color: #f5222d;
          padding: 0 8px;
          font-size: 12px;
          position: relative;

          &::after {
            text-transform: uppercase;
            content: attr(data-usertype);
          }
        }

        > div {
          border-top: 1px solid #f5222d;
          position: absolute;
          bottom: 4px;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .message-contents {
    white-space: pre-wrap;
  }
}

#last-read-messages {
  overflow-y: auto;
  flex: 2 0 auto;
  padding: 0 2px;
  cursor: pointer;

  .ant-timeline-item {
    margin-top: 6px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.ant-timeline-item-content div.ant-typography {
  margin-bottom: 6px;
}

.communications-list .ant-table-row {
  cursor: pointer;
}
