.white-table {
  background-color: #fff;
}

.safety-badge {
  vertical-align: middle;
  width: 16px;
  height: auto;
}

.ant-table-tbody .anticon-star {
  margin-right: 2px;
}

.ant-table-thead > tr > th.match-status,
.ant-table-tbody > tr > td.match-status,
.ant-table-thead > tr > th.profile-status,
.ant-table-tbody > tr > td.profile-status,
.ant-table-thead > tr > th.connection-status,
.ant-table-tbody > tr > td.connection-status {
  text-align: center;
}

.consumers-table,
.agents-table {
  .ant-badge-status {
    white-space: nowrap;
  }
}

.email-column a {
  display: block;
  max-width: 270px;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  vertical-align: top;

  &:nth-of-type(even) {
    margin-top: 16px;
  }
}

.carrier-data-wrapper {
  > .ant-typography {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-divider-horizontal.dash-divider {
  margin: 4px 0;
}
