.recommended-properties-section {
  .ant-list-item {
    justify-content: flex-start;
  }

  .ant-list-vertical {
    .ant-list-item-main {
      flex: unset;
      order: 1;
    }

    .ant-list-item-meta-title {
      margin-bottom: 2px;

      .ant-typography-secondary {
        font-weight: initial;
        font-size: 14px;
      }
    }

    .ant-list-item-meta {
      flex: unset;
      margin-bottom: 0;
    }

    .ant-list-item-action {
      margin-left: 0;
      margin-top: 0;
    }

    .ant-list-item-extra {
      margin-left: 0;
    }
  }

  .ant-divider-horizontal {
    margin: 6px 0;
  }

  .recommended-property-card {
    &__actions {
      &:hover {
        color: #1f91ff;

        path {
          &:first-child {
            fill: #1f91ff;
          }

          &:last-child {
            fill: #d1e8fd;
          }
        }
      }
    }

    &__details {
      &--list {
        & > div {
          line-height: 20px;
        }
      }
      &--image {
        margin-right: 12px;
        margin-bottom: 2px;
      }
    }
  }
}
