.message-input-section {
  background-color: white;
  margin-top: 24px;
  // height: 188px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;

  textarea {
    white-space: pre-wrap;
  }

  .ant-btn {
    flex: 1;
    margin-top: 1em;
  }
}
