.transaction-history-tab {
  .ant-list {
    .ant-list-header {
      font-weight: 600;
    }

    & + .ant-list {
      margin-top: 24px;
    }

    h4 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }

    &-item-meta-description {
      strong,
      span {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .ant-descriptions-item {
    font-weight: bolder;
    white-space: nowrap;
  }
}
