.login-form {
  width: 20rem;

  & &-submit {
    float: right;
  }
}

.dash-login {
  h3.ant-typography {
    text-align: center;
  }
}
