.matches-tables {
  .ant-table-title {
    font-size: 18px;
    font-weight: bold;
  }
}

.matches-info {
  .ant-avatar {
    height: 50px;
    width: 50px;
  }

  .ant-list-item-meta-title {
    font-size: 18px;
  }

  .ant-list-item-meta-description {
    font-size: 16px;
    color: inherit;
  }
}

.transactions-sub-table {
  td {
    white-space: pre-wrap;
  }
}

.feed-match-dropdown.ant-btn-group {
  display: flex;

  & > .ant-btn {
    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 0 0 auto;
    }
  }
}
