img {
  line-height: 1;
}

.content-shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.2);
  }
}

.blank-badge {
  .ant-badge-status-text {
    display: none;
  }
}

.ant-table-tbody {
  tr:nth-of-type(even) {
    background-color: #f8fafe;
  }
}

.bids-table {
  td,
  tr {
    cursor: pointer;
  }
}

.relative {
  position: relative;
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  .no-flex {
    flex: 0 0 auto;
  }

  .flex-one {
    flex: 1;
  }

  &.center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }
}

.no-wrap {
  white-space: nowrap;
}
