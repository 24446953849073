.ant-card.agent-profile {
  margin: 16px 0;
  border-radius: 4px;

  .photo-column {
    padding-right: 32px;
  }

  img.profile-photo {
    width: 100%;
  }
}

.ant-drawer-wrapper-body .ant-drawer-header-no-title {
  > button {
    position: relative;
    background: #eaeaea;

    svg {
      fill: #e91538;
    }
  }
}

.video-link {
  margin: 12px;
}
